import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`The best practice of development processes is the quality of the software.`}</p>
      <p>{`A collection of web applications may serve as valuable assets to a company that promotes reputation and intellectual property over a long period of time.`}</p>
      <p>{`Our development team fulfills all of our clients’ needs and develops rapid, scalable Web applications. It allows businesses to simplify daily operations and procedures in order to meet the main goals and objectives.`}</p>
      <p>{`The way companies rely on software has significantly changed, because technology is more relevant to tackling business issues every day and improving productivity. Earlier SaaS was the major model for companies, but with higher costs, limited customization and vague terms of data ownership, that was no longer the case. Moreover, customized software is required to meet business demands without addressing the limitations of off-shore software when corporate workflows are expanding.`}</p>
    </PageDescription>
    <h2>{`Application development`}</h2>
    <Row className="post-video-row" mdxType="Row">
      <Column colMd={5} colLg={12} className="bx--type-expressive-heading-03" mdxType="Column">
        <div style={{
          backgroundColor: '#343444',
          padding: '2rem'
        }}>
          <img {...{
            "src": "/images/appdev.svg",
            "alt": "Application Development"
          }}></img>
        </div>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      